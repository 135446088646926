import React from 'react';

import './Footer.css';

const ErrorPage = (props) => {

    let minHeight = window.innerHeight - 400;

    let errorText = '';
    if (props.language === 'PL') {
        errorText = 'Błąd 404 - ta strona nie istnieje. Kliknij w logo aby wrócić do strony głównej.';
    } else {
        errorText = 'Error 404 - this page doesn\'t exist. Click on logo to go back to home page.';
    }

    return (
        <div className='error-page' style={{'minHeight': `${minHeight}px`, 'marginTop': '200px', 'textAlign' : 'center'}}>
            <h1>{errorText}</h1>
        </div>
    )
}

export default ErrorPage;