import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import BookingBanner from './BookingBanner';

import './SharedLayout.css';

const SharedLayout = (props) => {
    return (
        <>
            <Navbar dane={props.dane} language={props.language} handleLanguage={props.handleLanguage}/>
            <div className='container'>
                <Outlet />
            </div>
            <BookingBanner lokacje={props.lokacje} language={props.language}/>
            <Footer dane={props.dane} language={props.language}/>
        </>
    )
}

export default SharedLayout;