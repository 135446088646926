import React from 'react';
import { Link } from 'react-router-dom';

import './Navbar.css';

const Navbar = (props) => {

    let switchStyle = {};
    if (props.language === 'PL') {
        switchStyle = {'transform': 'translateX(0px)'};
    } else {
        switchStyle = {'transform': 'translateX(30px)'};
    }

    return (
        
        <div className='navbar-container'>
            <div className="navbar">
                <div className='navbar-logo'>
                    <Link to='/'>
                        <img src='https://firebasestorage.googleapis.com/v0/b/gorskidom-afa98.appspot.com/o/logolarge.png?alt=media&token=ddd526fa-f196-40dd-91cb-549e839cb9b5' alt='GorskiDom'></img>
                    </Link>
                </div>
                <div className="navbar-phone">
                    <a href={`tel:${props.dane.telefon}`} className="navbar-phone-link" data-phone={props.dane.telefon}><i className="bi bi-telephone"></i></a>
                </div>
                <div className="navbar-mail">
                    <a href={`mailto:${props.dane.mail}`} className="navbar-mail-link" data-mail={props.dane.mail}><i className="bi bi-envelope"></i></a>
                </div>
                <div className="navbar-language">
                    <div style={switchStyle} className='navbar-language-switch'>{props.language}</div>
                    <div className='pl' onClick={props.handleLanguage}>PL</div>
                    <div className='en' onClick={props.handleLanguage}>EN</div>
                </div>
            </div>
        </div>
    )
}

export default Navbar;