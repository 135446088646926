import React from 'react';

import './BookingBanner.css';

const BookingBanner = props => {

    function handleBookingSubmit(event) {
        event.preventDefault();
        window['sendBookingInfo']();
    }

    return (
        <div className='booking-container'>
            <button className='booking-submit' type='submit' onClick={handleBookingSubmit}>{props.language === 'PL' ? 'Rezerwuj online' : 'Book online'}</button>
        </div>
    )
}

export default BookingBanner;