import React, { useState, useEffect } from 'react';
import { initializeApp } from "firebase/app";
import { getFirestore, doc, collection, setDoc, getDocs } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";

import './Admin.css';

const firebaseConfig = {
    apiKey: "AIzaSyCE0PRd24_ah5bdU6MvBpjZ5pUvV9b_c9c",
    authDomain: "gorskidom-afa98.firebaseapp.com",
    projectId: "gorskidom-afa98",
    storageBucket: "gorskidom-afa98.appspot.com",
    messagingSenderId: "353050613543",
    appId: "1:353050613543:web:0aed81ed1f2e9b16547b02",
    measurementId: "G-3J54NQLJWB"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app)
const auth = getAuth();

const Admin = (props) => {

    const [loggedIn, setLoggedIn] = useState(false);
    const [loginData, setLoginData] = useState({
        login: '',
        password: ''
    });
    const [dane, setDane] = useState({});
    const [lokacje, setLokacje] = useState([]);
    const [aktywnaLokacja, setAktywnaLokacja] = useState('');
    const [lokacja, setLokacja] = useState({});


    function handleLoginChange(event) {
        const {name, value} = event.target;
        setLoginData(prevFormData => {
            return ({
                ...prevFormData,
                [name]: value
            });
        });
    };

    function handleLogin(event) {
        event.preventDefault();
        signInWithEmailAndPassword(auth, loginData.login, loginData.password)
        .then((userCredential) => {
            setLoggedIn(true);
        })
        .catch((error) => {
            console.log(error);
        });
    };

    function handleLogout(event) {
        event.preventDefault();
        signOut(auth).then(() => {
            setLoggedIn(false);
            setLoginData({
                login: '',
                password: ''
            });
            window.location.reload();
          }).catch((error) => {
            console.log(error);
          });
    };

    function handleDaneChange(event) {
        const {name, value} = event.target;
        setDane(prevFormData => {
            return ({
                ...prevFormData,
                [name]: value
            });
        });
    };

    function handleDaneUpload(event) {
        event.preventDefault();
        setDoc(doc(db, 'dane', 'dane'), {
            ...dane
            });
    };

    function handleAktywnaLokacjaChange(event) {
        if (event.target.value === 'nowa-lokacja') {
            setAktywnaLokacja('nowa-lokacja');
            setLokacja(() => {
                return {
                    adres: '',
                    aktywna: false,
                    atrakcjeEn: [''],
                    atrakcjePl: [''],
                    cena: '',
                    link: '',
                    mapa: '',
                    maxOsob: '',
                    profitroomID: '',
                    nazwaEn: '',
                    nazwaPl: '',
                    opisEn: '',
                    opisPl: '',
                    powierzchnia: '',
                    zdjecia: [''],
                    zdjeciaOpisEn: [''],
                    zdjeciaOpisPl: [''],
                    zdjecieHome: ['']
                }
            });
        } else {
            setAktywnaLokacja(event.target.value);
            setLokacja(() => {
                let result = lokacje.find(obj => {
                    return obj.link === event.target.value;
                });
                return result;
            });
        }
    }

    function handleLokacjaChange(event) {
        const {name, value, type, checked} = event.target;
        setLokacja(prevFormData => {
            return ({
                ...prevFormData,
                [name]: type === 'checkbox' ? checked : value
            });
        });
    };

    function handleAtrakcjeChange(event, index) {
        const name = event.target.name;
        const id = event.target.id;
        setLokacja(prevLokacja => {
            let newLokacja = {...prevLokacja};
            if (name === 'atrakcjePl') {
                newLokacja.atrakcjePl[index] = event.target.value;
            } else if (name === 'atrakcjeEn') {
                newLokacja.atrakcjeEn[index] = event.target.value;
            } else if (id === 'deletePl') {
                newLokacja.atrakcjePl.splice(index, 1);
            } else if (id === 'deleteEn') {
                newLokacja.atrakcjeEn.splice(index, 1);
            } else if (id === 'new-atrakcja') {
                console.log(id);
                newLokacja.atrakcjeEn.push('');
                newLokacja.atrakcjePl.push('');
            }
            return newLokacja
        })
    };

    function handleZdjeciaChange(event, index) {
        const name = event.target.name;
        const id = event.target.id;
        setLokacja(prevLokacja => {
            let newLokacja = {...prevLokacja};
            if (name === 'opisPl') {
                newLokacja.zdjeciaOpisPl[index] = event.target.value;
            } else if (name === 'opisEn') {
                newLokacja.zdjeciaOpisEn[index] = event.target.value;
            } else if (name === 'link') {
                newLokacja.zdjecie[index] = event.target.value;
            } else if (id === 'up' && index > 0) {
                let tempZdjecie = newLokacja.zdjecia[index-1];
                let tempOpisPl = newLokacja.zdjeciaOpisPl[index-1];
                let tempOpisEn = newLokacja.zdjeciaOpisEn[index-1];
                newLokacja.zdjecia[index-1] = newLokacja.zdjecia[index];
                newLokacja.zdjeciaOpisPl[index-1] = newLokacja.zdjeciaOpisPl[index];
                newLokacja.zdjeciaOpisEn[index-1] = newLokacja.zdjeciaOpisEn[index];
                newLokacja.zdjecia[index] = tempZdjecie;
                newLokacja.zdjeciaOpisPl[index] = tempOpisPl;
                newLokacja.zdjeciaOpisEn[index] = tempOpisEn;
            } else if (id === 'down' && index < newLokacja.zdjecia.length-1) {
                let tempZdjecie = newLokacja.zdjecia[index+1];
                let tempOpisPl = newLokacja.zdjeciaOpisPl[index+1];
                let tempOpisEn = newLokacja.zdjeciaOpisEn[index+1];
                newLokacja.zdjecia[index+1] = newLokacja.zdjecia[index];
                newLokacja.zdjeciaOpisPl[index+1] = newLokacja.zdjeciaOpisPl[index];
                newLokacja.zdjeciaOpisEn[index+1] = newLokacja.zdjeciaOpisEn[index];
                newLokacja.zdjecia[index] = tempZdjecie;
                newLokacja.zdjeciaOpisPl[index] = tempOpisPl;
                newLokacja.zdjeciaOpisEn[index] = tempOpisEn;
            } else if (id === 'new-zdjecie') {
                newLokacja.zdjecia.push('');
                newLokacja.zdjeciaOpisPl.push('');
                newLokacja.zdjeciaOpisEn.push('');
            } else if (id === 'delete') {
                newLokacja.zdjecia.splice(index, 1);
                newLokacja.zdjeciaOpisPl.splice(index, 1);
                newLokacja.zdjeciaOpisEn.splice(index, 1);
            }
            return newLokacja
        })
    };

    function handleLokacjaUpload(event) {
        event.preventDefault();
        setDoc(doc(db, 'domki', lokacja.link), {
            ...lokacja
            });
    };

    useEffect(() => {

        let fetchData = async () => {
    
          let data = await getDocs(collection(db, "dane"));
          data.forEach((doc) => {
            setDane(doc.data());
          });
    
          let lokacjeDB = [];
    
          data = await getDocs(collection(db, "domki"));
          data.forEach((doc) => {
            lokacjeDB.push(doc.data());
          });
    
          setLokacje(lokacjeDB);
        };
    
        fetchData();
    
    },[]);

    return (
        <div className='admin-container'>
            {loggedIn && (
                <>
                    <button className='logout-button' onClick={handleLogout}>Wyloguj</button>
                    <h1>Dane</h1>
                    <form className='short-form' onSubmit={handleDaneUpload}>
                        <p>Adres e-mail</p>
                        <input
                            type='text'
                            placeholder='mail'
                            className='short-form-input'
                            name='mail'
                            required
                            onChange={handleDaneChange}
                            value={dane.mail}
                        />
                        <p>Telefon</p>
                        <input 
                            type='text'
                            placeholder='telefon'
                            className='short-form-input'
                            name='telefon'
                            required
                            onChange={handleDaneChange}
                            value={dane.telefon}
                        />
                        <button className='short-form-submit'>Zapisz zmiany w danych</button>
                    </form>
                    <h1>Wybierz lokację do edycji</h1>
                    <select id="lokacje" name="lokacje" value={aktywnaLokacja} onChange={handleAktywnaLokacjaChange}>
                        <option value=''>Wybierz</option>
                        {lokacje.map(lokacja => {
                            return (
                                <option key={lokacja.link} value={lokacja.link}>{lokacja.nazwaPl}</option>
                            )
                        })}
                        <option value='nowa-lokacja'>Dodaj nową lokację</option>
                    </select>
                    {aktywnaLokacja !== '' && (
                        <form className='lokacja-form' onSubmit={handleLokacjaUpload}>
                            <label>Lokacja aktywna 
                                <input 
                                    type='checkbox'
                                    name='aktywna'
                                    onChange={handleLokacjaChange}
                                    checked={lokacja.aktywna}
                                />
                             </label>
                            <p>Nazwa Pl</p>
                            <input
                                type='text'
                                placeholder='nazwaPl'
                                className='lokacja-form-input'
                                name='nazwaPl'
                                required
                                onChange={handleLokacjaChange}
                                value={lokacja.nazwaPl}
                            />
                            <p>Nazwa en</p>
                            <input 
                                type='text'
                                placeholder='nazwaPl'
                                className='lokacja-form-input'
                                name='nazwaPl'
                                onChange={handleLokacjaChange}
                                value={lokacja.nazwaEn}
                            />
                            <p>Profitroom ID</p>
                            <input
                                type='text'
                                placeholder='Profitroom ID'
                                className='lokacja-form-input'
                                name='profitroomID'
                                required
                                onChange={handleLokacjaChange}
                                value={lokacja.profitroomID}
                            />
                            <p>Adres</p>
                            <input 
                                type='text'
                                placeholder='adres'
                                className='lokacja-form-input'
                                name='adres'
                                onChange={handleLokacjaChange}
                                value={lokacja.adres}
                            />
                            <p>Cena</p>
                            <input 
                                type='text'
                                placeholder='cena'
                                className='lokacja-form-input'
                                name='cena'
                                onChange={handleLokacjaChange}
                                value={lokacja.cena}
                            />
                            <p>Link - do zmiany tylko przy nowej lokacji</p>
                            <input 
                                type='text'
                                placeholder='link'
                                className='lokacja-form-input'
                                name='link'
                                required
                                onChange={handleLokacjaChange}
                                value={lokacja.link}
                            />
                            <p>Mapa</p>
                            <input 
                                type='text'
                                placeholder='mapa'
                                className='lokacja-form-input'
                                name='mapa'
                                onChange={handleLokacjaChange}
                                value={lokacja.mapa}
                            />
                            <p>Max. osób</p>
                            <input 
                                type='text'
                                placeholder='maxOsob'
                                className='lokacja-form-input'
                                name='maxOsob'
                                onChange={handleLokacjaChange}
                                value={lokacja.maxOsob}
                            />
                            <p>Powierzchnia</p>
                            <input 
                                type='text'
                                placeholder='powierzchnia'
                                className='lokacja-form-input'
                                name='powierzchnia'
                                onChange={handleLokacjaChange}
                                value={lokacja.powierzchnia}
                            />
                            <p>Zdjecie Home</p>
                            <input 
                                type='text'
                                placeholder='zdjecieHome'
                                className='lokacja-form-input'
                                name='zdjecieHome'
                                onChange={handleLokacjaChange}
                                value={lokacja.zdjecieHome}
                            />
                            <p>Opis Pl</p>
                            <textarea
                                placeholder='opis pl'
                                className='lokacja-form-textarea'
                                name='opisPl'
                                onChange={handleLokacjaChange}
                                value={lokacja.opisPl}
                            ></textarea>
                            <p>Opis En</p>
                            <textarea
                                placeholder='opis en'
                                className='lokacja-form-textarea'
                                name='opisEn'
                                onChange={handleLokacjaChange}
                                value={lokacja.opisEn}
                            ></textarea>
                            <p>Atrakcje Pl</p>
                            <div className='atrakcje'>
                                {lokacja.atrakcjePl.map(atrakcja => {
                                    let index = lokacja.atrakcjePl.findIndex(item => item === atrakcja)
                                    return (
                                        <div>
                                            <input 
                                                key={lokacja.atrakcjePl[index]}
                                                type='text'
                                                placeholder='atrakcja'
                                                className='atrakcja-form-input'
                                                name='atrakcjePl'
                                                onChange={event => handleAtrakcjeChange(event, index)}
                                                value={lokacja.atrakcjePl[index]}
                                            />
                                            <div className='atrakcja-usun' onClick={event => handleAtrakcjeChange(event, index)}>
                                                <i id='deletePl' className="bi bi-x-circle"></i>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <p>Atrakcje En</p>
                            <div className='atrakcje'>
                                {lokacja.atrakcjeEn.map(atrakcja => {
                                    let index = lokacja.atrakcjeEn.findIndex(item => item === atrakcja)
                                    return (
                                        <div>
                                            <input 
                                                key={lokacja.atrakcjeEn[index]}
                                                type='text'
                                                placeholder='atrakcja'
                                                className='atrakcja-form-input'
                                                name='atrakcjeEn'
                                                onChange={event => handleAtrakcjeChange(event, index)}
                                                value={lokacja.atrakcjeEn[index]}
                                            />
                                            <div className='atrakcja-usun' onClick={event => handleAtrakcjeChange(event, index)}>
                                                <i id='deleteEn' className="bi bi-x-circle"></i>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className='new-atrakcja'>
                                <p><i id='new-atrakcja' onClick={event => handleAtrakcjeChange(event)} className="bi bi-plus-circle"></i> Dodaj atrakcję</p>
                            </div>
                            <p>Zdjęcia</p>
                            <div className='zdjecia'>
                                {lokacja.zdjecia.map(zdjecie => {
                                    let index = lokacja.zdjecia.findIndex(item => item === zdjecie)
                                    return (
                                        <div key={lokacja.zdjecia[index]} className='zdjecie' style={index % 2 === 0 ? {'backgroundColor': 'rgb(240,240,240)'} : {'backgroundColor': 'white'}}>
                                            <div className='strzalki'>
                                                <div onClick={event => handleZdjeciaChange(event, index)}>
                                                    <i id='up' className="bi bi-arrow-up-circle"></i>
                                                </div>
                                                <div onClick={event => handleZdjeciaChange(event, index)}>
                                                    <i id='down' className="bi bi-arrow-down-circle"></i>
                                                </div>
                                            </div>
                                            <div className='zdjecie-mini'>
                                                <img src={lokacja.zdjecia[index]} alt={lokacja.zdjeciaOpisPl[index]}/>
                                            </div>
                                            <div className='zdjecie-detale'>
                                                <div>
                                                    <label>Link do zdjecia</label>
                                                    <input 
                                                        type='text'
                                                        placeholder='link'
                                                        className='zdjecie-form-input'
                                                        name='link'
                                                        onChange={event => handleZdjeciaChange(event, index)}
                                                        value={lokacja.zdjecia[index]}
                                                    />
                                                </div>
                                                <div>
                                                    <label>Opis Pl</label>
                                                    <input 
                                                        type='text'
                                                        placeholder='opis pl'
                                                        className='zdjecie-form-input'
                                                        name='opisPl'
                                                        onChange={event => handleZdjeciaChange(event, index)}
                                                        value={lokacja.zdjeciaOpisPl[index]}
                                                    />
                                                </div>
                                                <div>
                                                    <label>Opis En</label>
                                                    <input 
                                                        type='text'
                                                        placeholder='opis en'
                                                        className='zdjecie-form-input'
                                                        name='opisEn'
                                                        onChange={event => handleZdjeciaChange(event, index)}
                                                        value={lokacja.zdjeciaOpisEn[index]}
                                                    />
                                                </div>
                                            </div>
                                            <div className='zdjecie-usun'>
                                                <div onClick={event => handleZdjeciaChange(event, index)}>
                                                    <p>Usuń zdjęcie</p>
                                                    <p><i id='delete' className="bi bi-x-circle"></i></p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                <div className='new-zdjecie' onClick={event => handleZdjeciaChange(event)}>
                                    <p><i id='new-zdjecie' className="bi bi-plus-circle"></i> Dodaj zdjęcie</p>
                                </div>
                            </div>
                            <button className='lokacja-form-submit'>Zapisz zmiany w lokacji</button>
                        </form>
                    )}
                </>
            )}
            {!loggedIn && (
                <>
                    <h1>Login</h1>
                    <form className='login-form' onSubmit={handleLogin}>
                        <input
                            type='text'
                            placeholder='login'
                            className='login-form-input'
                            name='login'
                            required
                            onChange={handleLoginChange}
                            value={loginData.login}
                        />
                        <input 
                            type='text'
                            placeholder='hasło'
                            className='login-form-input'
                            name='password'
                            required
                            onChange={handleLoginChange}
                            value={loginData.password}
                        />
                        <button className='login-form-submit'>Zaloguj</button>
                    </form>
                </>
            )}
        </div>
    )
}

export default Admin;