import React from 'react';

import './Footer.css';

const Footer = (props) => {
    return (
        <div className='footer-container'>
            <div className='footer'>
                <div>
                    <img src='https://firebasestorage.googleapis.com/v0/b/gorskidom-afa98.appspot.com/o/logolarge.png?alt=media&token=ddd526fa-f196-40dd-91cb-549e839cb9b5' alt='GorskiDom'></img>
                </div>
                <div>
                    <h2>{props.language === 'PL' ? 'TELEFON' : 'PHONE'}:</h2>
                    <p><a href={`tel:${props.dane.telefon}`}><i className="bi bi-telephone"></i> {props.dane.telefon}</a></p>
                </div>
                <div>
                    <h2>E-MAIL:</h2>
                    <p><a href={`mailto:${props.dane.mail}`}><i className="bi bi-envelope"></i> {props.dane.mail}</a></p>
                </div>
                <div>
                    <h2>{props.language === 'PL' ? 'DANE DO PRZELEWU' : 'PAYMENT DETAILS'}:</h2>
                    <p>Andromeda Harasimowicz</p>
                    <p>Bank Pekao</p>
                    <p>65 1240 5165 1111 0011 1175 5942</p>
                    <p>{props.language === 'EN' && 'IBAN: PL, SWIFT: PKOPPLPW'}</p>
                </div>
            </div>
        </div>
    )
}

export default Footer;