import React, { useState } from 'react';

import './DetailsGallery.css';

const DetailsGallery = (props) => {

    const [activePhoto, setActivePhoto] = useState(0);

    let handleClick = (event) => {
        setActivePhoto(() => {
            return parseInt(event.target.getAttribute('id'));
        })
    }

    let handleLeftArrow = () => {
        setActivePhoto( current => {
            if (activePhoto === 0) {
                return props.lokacja.zdjecia.length - 1
            } else {
                return current - 1
            }
        })
    }

    let handleRightArrow = () => {
        setActivePhoto( current => {
            if (activePhoto === props.lokacja.zdjecia.length - 1) {
                return 0
            } else {
                return current + 1
            }
        })
    }
    
    return (
        <div className='photo-gallery'>
            <div className='photo-gallery-l'>
                <img src={props.lokacja.zdjecia[activePhoto]} alt='house'/>
                <div className='gallery-buttons'>
                    <div className='gallery-buttons-left' onClick={handleLeftArrow}>
                        <i className="bi bi-arrow-left-short"></i>
                    </div>
                    <div className='gallery-buttons-right' onClick={handleRightArrow}>
                        <i className="bi bi-arrow-right-short"></i>
                    </div>
                </div>
            </div>
            <div className='photo-gallery-caption'>
                <p>{props.language === 'PL' ? props.lokacja.zdjeciaOpisPl[activePhoto] : props.lokacja.zdjeciaOpisEn[activePhoto]}</p>
            </div>
            <div className='photo-gallery-s'>
                {props.lokacja.zdjecia.map(zdjecie => {
                    return (
                        <div key={props.lokacja.zdjecia.indexOf(zdjecie)} id={props.lokacja.zdjecia.indexOf(zdjecie)} className='photo-small' onClick={handleClick} style={{'backgroundImage': `url(${zdjecie})`, 'backgroundSize': 'cover', 'backgroundPosition': 'center'}}>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default DetailsGallery;