import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import HomeCarousel from './HomeCarousel';

import './Home.css';



const Home = (props) => {

    const ref = useRef(null);

    let allText = {
        pl: {
            parking: 'PRYWATNY PARKING',
            wifi: 'BEZPŁATNE WIFI',
            kuchnia: 'W PEŁNI WYPOSAŻONA KUCHNIA',
            grill: 'SPRZĘT DO GRILLOWANIA',
            maxOsob: 'Max. osób',
            powierzchnia: 'Powierzchnia',
            przyciskWiecej: 'Więcej',
            opinie: [
                [
                    'Piękny, klimatyczny domek, najpiękniejszy widok na góry! Na pewno planując wyjazd do Zakopanego będziemy patrzeć na nocleg tylko w tym miejscu! Super kontakt z właścicielem co jest dużym plusem, dodatkowo domek cudownie urządzony, na prawde bez żadnych zastrzeżeń! Szczerze polecam!',
                    'Magdalena'
                ],
                [
                    'Lokalizacja przepiękna, domek czysciutki. Właściciel bardzo sympatyczny. I na pewno tam wrócimy.',
                    'Urszula'
                ],
                [
                    'Świetne miejsce w świetnej lokalizacji z pięknym widokiem. Kontakt z właścicielem bardzo dobry. Pobyt był krótki ale warty zapamiętania i powtórzenia. Gospodarz zadbał aby domek był czysty i żeby było w nim ciepło. Nie pozostaje nic tylko polecić...',
                    'Maksymilian'
                ]
            ]
        },
        en: {
            parking: 'PRIVATE PARKING',
            wifi: 'FREE WIFI',
            kuchnia: 'FULLY EQUIPPED KITCHEN',
            grill: 'GRILLING EQUIPMENT',
            maxOsob: 'Max. people',
            powierzchnia: 'Area',
            przyciskWiecej: 'More',
            opinie: [
                [
                    'A beautiful, atmospheric house, the most beautiful view of the mountains! Certainly, when planning a trip to Zakopane, we will look at accommodation only in this place! Great contact with the owner, which is a big plus, in addition, the house is wonderfully decorated, truly no issues! I sincerely recommend!',
                    'Magdalena'
                ],
                [
                    'Beautiful location, clean house. The owner is very nice. And we will definitely go back there.',
                    'Urszula'
                ],
                [
                    'Great place in a great location with a beautiful view. Very good contact with the owner. The stay was short but worth remembering and repeating. The host made sure that the house was clean and that it was warm. There is nothing left but to recommend ...',
                    'Maksymilian'
                ]
            ]
        }
    }

    let activeText = props.language === 'PL' ? allText.pl : allText.en;

    return (
        <>
            <HomeCarousel ref={ref} language={props.language}/>
            <div className='dividing-line'></div>
            <div className='hero-icons'>
                <div>
                    <img src='https://firebasestorage.googleapis.com/v0/b/gorskidom-afa98.appspot.com/o/parking.svg?alt=media&token=cf92d917-4da6-4cf6-9ef0-bfc1974f7133' alt='Parking'/>
                    <p>{activeText.parking}</p>
                </div>
                <div>
                    <img src='https://firebasestorage.googleapis.com/v0/b/gorskidom-afa98.appspot.com/o/wifi.svg?alt=media&token=6f21d027-b28e-4e27-8c0d-1e7329021425' alt='Wifi'/>
                    <p>{activeText.wifi}</p>
                </div>
                <div>
                    <img src='https://firebasestorage.googleapis.com/v0/b/gorskidom-afa98.appspot.com/o/kitchen.svg?alt=media&token=5d9c9b30-7d85-4115-b0d3-7b33ead23343' alt='Kitchen'/>
                    <p ref={ref}>{activeText.kuchnia}</p>
                </div>
                <div>
                    <img src='https://firebasestorage.googleapis.com/v0/b/gorskidom-afa98.appspot.com/o/grill.svg?alt=media&token=e82141a7-0981-4eb4-927b-3edb8cc279b4' alt='Grill'/>
                    <p>{activeText.grill}</p>
                </div>
            </div>
            <div className='dividing-line'></div>
            <div className='houses'>
                {props.lokacje.map(lokacja => {
                    if (lokacja.aktywna) {
                        if (window.innerWidth <= 600) {
                            return (
                                <div className='house' key={props.lokacje.indexOf(lokacja)}>
                                    <div className='house-image' style={{'backgroundImage': `url(${lokacja.zdjecieHome})`, 'backgroundSize': 'cover', 'backgroundPosition': 'center'}}></div>
                                    <div className='house-details'>
                                        <div>
                                            <img src='https://firebasestorage.googleapis.com/v0/b/gorskidom-afa98.appspot.com/o/kwiatki.svg?alt=media&token=744812d8-0e96-486a-8594-f9396dfc06b9' style={{'height': '50px'}} alt='Flowers'/>
                                            <h2>{props.language === 'PL' ? lokacja.nazwaPl : lokacja.nazwaEn}</h2>
                                            <p>{lokacja.adres}</p>
                                            <p><i className="bi bi-people"></i>{activeText.maxOsob} {lokacja.maxOsob}</p>
                                            <p><i className="bi bi-aspect-ratio"></i>{activeText.powierzchnia} {lokacja.powierzchnia}m<sup>2</sup></p>
                                            <Link to={`details/${lokacja.link}`}>
                                                <div className='house-button'><p>{activeText.przyciskWiecej}</p><i className="bi bi-arrow-right-short"></i></div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        } else {
                            if (props.lokacje.indexOf(lokacja) % 2 === 0) {
                                return (
                                    <div className='house' key={props.lokacje.indexOf(lokacja)}>
                                        <div className='house-image' style={{'backgroundImage': `url(${lokacja.zdjecieHome})`, 'backgroundSize': 'cover', 'backgroundPosition': 'center'}}></div>
                                        <div className='house-details'>
                                            <div>
                                                <img src='https://firebasestorage.googleapis.com/v0/b/gorskidom-afa98.appspot.com/o/kwiatki.svg?alt=media&token=744812d8-0e96-486a-8594-f9396dfc06b9' style={{'height': '50px'}} alt='Flowers'/>
                                                <h2>{props.language === 'PL' ? lokacja.nazwaPl : lokacja.nazwaEn}</h2>
                                                <p>{lokacja.adres}</p>
                                                <p><i className="bi bi-people"></i>{activeText.maxOsob} {lokacja.maxOsob}</p>
                                                <p><i className="bi bi-aspect-ratio"></i>{activeText.powierzchnia} {lokacja.powierzchnia}m<sup>2</sup></p>
                                                <Link to={`details/${lokacja.link}`}>
                                                    <div className='house-button'><p>{activeText.przyciskWiecej}</p><i className="bi bi-arrow-right-short"></i></div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div className='house' key={props.lokacje.indexOf(lokacja)} style={{'backgroundColor': 'rgb(240,240,240)'}}>
                                        <div className='house-details'>
                                            <div>
                                                <img src='https://firebasestorage.googleapis.com/v0/b/gorskidom-afa98.appspot.com/o/kwiatki.svg?alt=media&token=744812d8-0e96-486a-8594-f9396dfc06b9' style={{'height': '50px'}} alt='Flowers'/>
                                                <h2>{props.language === 'PL' ? lokacja.nazwaPl : lokacja.nazwaEn}</h2>
                                                <p>{lokacja.adres}</p>
                                                <p><i className="bi bi-people"></i>{activeText.maxOsob} {lokacja.maxOsob}</p>
                                                <p><i className="bi bi-aspect-ratio"></i>{activeText.powierzchnia} {lokacja.powierzchnia}m<sup>2</sup></p>
                                                <Link to={`details/${lokacja.link}`}>
                                                    <div className='house-button'><p>{activeText.przyciskWiecej}</p><i className="bi bi-arrow-right-short"></i></div>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className='house-image' style={{'backgroundImage': `url(${lokacja.zdjecieHome})`, 'backgroundSize': 'cover', 'backgroundPosition': 'center'}}></div>
                                    </div>
                                )
                            }
                        }
                    }
                    return null;
                })}
            </div>
            <div className='dividing-line'></div>
            <div className='reviews'>
                <div className='review'>
                    <p>
                        <i className="bi bi-quote"></i>
                        {activeText.opinie[0][0]}
                        <i className="bi bi-quote"></i>
                    </p>
                    <p>{activeText.opinie[0][1]}</p>
                </div>
                <div className='review'>
                    <p>
                        <i className="bi bi-quote"></i>
                        {activeText.opinie[1][0]}
                        <i className="bi bi-quote"></i>
                    </p>
                    <p>{activeText.opinie[1][1]}</p>
                </div>
                <div className='review'>
                    <p>
                        <i className="bi bi-quote"></i>
                        {activeText.opinie[2][0]}
                        <i className="bi bi-quote"></i>
                    </p>
                    <p>{activeText.opinie[2][1]}</p>
                </div>
            </div>
            <div className='dividing-line'></div>

        </>
    )
}

export default Home;