import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Admin from './components/Admin';
import Details from './components/Details';
import ErrorPage from './components/ErrorPage';
import Home from './components/Home';
import SharedLayout from './components/SharedLayout';

import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCE0PRd24_ah5bdU6MvBpjZ5pUvV9b_c9c",
    authDomain: "gorskidom-afa98.firebaseapp.com",
    projectId: "gorskidom-afa98",
    storageBucket: "gorskidom-afa98.appspot.com",
    messagingSenderId: "353050613543",
    appId: "1:353050613543:web:0aed81ed1f2e9b16547b02",
    measurementId: "G-3J54NQLJWB"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app)

function App() {

  const [dane, setDane] = useState({})
  const [lokacje, setLokacje] = useState([])
  const [language, setLanguage] = useState('PL');

  useEffect(() => {

    let fetchData = async () => {

      let data = await getDocs(collection(db, "dane"));
      data.forEach((doc) => {
        setDane(doc.data());
      });

      let lokacjeDB = [];

      data = await getDocs(collection(db, "domki"));
      data.forEach((doc) => {
        lokacjeDB.push(doc.data());
      });

      setLokacje(lokacjeDB);
    };

    fetchData();

  },[]);

  function handleLanguage() {
    setLanguage(prevMode => {
        return prevMode === 'PL' ? 'EN' : 'PL';
    })
  }

  return (
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<SharedLayout dane={dane} lokacje={lokacje} language={language} handleLanguage={handleLanguage}/>}>
            <Route index element={<Home lokacje={lokacje} language={language}/>} />
            <Route exact path="details/:link" element={<Details dane={dane} lokacje={lokacje} language={language}/>} />
            <Route path="admin" element={<Admin />} />
            <Route path="*" element={<ErrorPage language={language}/>} />
          </Route>
        </Routes>
    </BrowserRouter>
  );
}

export default App;
