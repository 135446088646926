import React, { useState, useEffect, forwardRef }from 'react';

import  './HomeCarousel.css';


const HomeCarousel = forwardRef((props, ref) => {

    const [currentSlide, setCurrentSlide] = useState(1);
    useEffect(() => {
        setTimeout(() => {
            switch (currentSlide) {
                case 1:
                    setCurrentSlide(2);
                    break;
                case 2:
                    setCurrentSlide(3);
                    break;
                case 3:
                    setCurrentSlide(1);
                    break;
                default:
                    setCurrentSlide(1);
            }
        }, 7000) 
    })

    const handleScrollDown = () => {
        ref.current.scrollIntoView({behavior: 'smooth'});
    };


    return (
        <div className='carousel'>
            <div className='slide1' style={currentSlide === 1 ? {'opacity':'1'} : {'opacity':'0'}}>
                <div className='carousel-powerline'>
                    <h1>{props.language === 'PL' ? 'Zacznij dzień od cudownego widoku' : 'Start the day with a wonderful view'}</h1>
                    <h2>{props.language === 'PL' ? 'Górski Dom Kościelisko' : 'Gorski Dom Koscielisko'}</h2>
                </div>
            </div>
            <div className='slide2' style={currentSlide === 2 ? {'opacity':'1'} : {'opacity':'0'}}>
                <div className='carousel-powerline'>
                    <h1>{props.language === 'PL' ? 'Zrelaksuj się w samym sercu Zakopanego' : 'Relax in the heart of Zakopane'}</h1>
                    <h2>{props.language === 'PL' ? 'Górski Apartament Zakopane' : 'Gorski Apartament Zakopane'}</h2>
                </div>
            </div>
            <div className='slide3' style={currentSlide === 3 ? {'opacity':'1'} : {'opacity':'0'}}>
                <div className='carousel-powerline'>
                    <h1>{props.language === 'PL' ? 'Spędź niezapomniane chwile z rodziną' : 'Spend unforgettable moments with your family'}</h1>
                    <h2>{props.language === 'PL' ? 'Górski Dom Gubałówka' : 'Gorski Dom Gubalowka'}</h2>
                </div>
            </div>
            <div className='carousel-actionbar'>
                <div className='carousel-text' onClick={handleScrollDown}>
                    <h1>{props.language === 'PL' ? 'Zobacz naszą ofertę' : 'See our offer'} <i className="bi bi-arrow-down-circle"></i></h1>
                </div>
            </div>
        </div>
    )
});

export default HomeCarousel;