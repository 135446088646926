import React from 'react';
import { useParams } from 'react-router-dom';
import DetailsGallery from './DetailsGallery';

import './Details.css';

const Details = (props) => {
    window.scrollTo(0,0);
    let minHeight = window.innerHeight - 300;
    let lokacje = [];
    lokacje = props.lokacje;
    if (lokacje.length === 0) {
        return (
            <>
                <div className='details-navbar-bg'></div>
                <div className='details' style={{'minHeight': `${minHeight}px`}}></div>
            </>
        )
    } else {
    let { link } = useParams();
    let lokacja = props.lokacje.find(lokacja => lokacja.link === link);
    let atrakcje;
    if (props.language === 'PL') {
        atrakcje = lokacja.atrakcjePl.map(atrakcja => {
            return (
                <li key={lokacja.atrakcjePl.indexOf(atrakcja)}>{atrakcja}</li>
            )
        })
    } else {
        atrakcje = lokacja.atrakcjeEn.map(atrakcja => {
            return (
                <li key={lokacja.atrakcjeEn.indexOf(atrakcja)}>{atrakcja}</li>
            )
        })
    }

    return (
        <>
            <div className='details-navbar-bg'></div>
            <div className='dividing-line'></div>
            <div className='details' style={{'minHeight': `${minHeight}px`}}>
                <div className='details-left'>
                    <div>
                        <img src='https://firebasestorage.googleapis.com/v0/b/gorskidom-afa98.appspot.com/o/kwiatki.svg?alt=media&token=744812d8-0e96-486a-8594-f9396dfc06b9' style={{'height': '50px'}} alt='Flowers'/>
                    </div>
                    <h1>{props.language === 'PL' ? lokacja.nazwaPl : lokacja.nazwaEn}</h1>
                    <h4>{lokacja.adres}</h4>
                    <h4><i className="bi bi-people"></i> {props.language === 'PL' ? 'Max. osób' : 'Max. people'} {lokacja.maxOsob}</h4>
                    <h4><i className="bi bi-aspect-ratio"></i> {props.language === 'PL' ? 'Powierzchnia' : 'Area'} {lokacja.powierzchnia}m<sup>2</sup></h4>
                    <p>{props.language === 'PL' ? lokacja.opisPl : lokacja.opisEn}</p>
                    <p>{props.language === 'PL' ? 'Najblisze atrakcje' : 'Closest attractions'}:</p>
                    <ul>{atrakcje}</ul>
                    <p>{props.language === 'PL' ? 'Cena wynajmu za dobę' : 'Price per day'} {lokacja.cena}{props.language === 'PL' ? 'zł w zależności od sezonu, ilości osób i długości pobytu.' : 'zł depending on season, number of people and length of stay.'}</p>
                    <p>{props.language === 'PL' ? 'Na wszystkie pytania odpowiemy oraz ustalimy rezerwację pod numerem telefonu' : 'We will answer all your questions and make a reservation by phone'} {props.dane.telefon}.</p>
                    <p>{props.language === 'PL' ? 'Serdecznie zapraszamy' : 'Warmly Welcome'}!</p>
                    <h4>{props.language === 'PL' ? 'Lokalizacja' : 'Location'}:</h4>
                    <div className='details-map'>
                        <iframe src={lokacja.mapa} width="400" height="300" style={{'border':'0'}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='house location'></iframe>
                    </div>
                </div>
                <div className='details-right'>
                    <DetailsGallery lokacja={lokacja} language={props.language}/>
                </div>
            </div>
            <div className='dividing-line'></div>
        </>
    )
}
}

export default Details;